import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        console.log(`Page changed to: ${location.pathname}`);
        window.gtag("config", "G-KVZRGJJ90P", { page_path: location.pathname });
        
        // Gửi thông tin trang đến Mixpanel
        mixpanel.track("Page View", {
            page: location.pathname,
        });
    }, [location]);
};

export default usePageTracking;
