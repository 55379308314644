// P5Effect.js
import React, { useEffect } from 'react';
import p5 from 'p5';

const P5Effect = () => {
  useEffect(() => {
    const sketch = (p) => {
      let floatingTexts = [];

      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight);
        p.textFont('Georgia');
        p.textSize(24);
        for (let i = 0; i < 30; i++) {
          floatingTexts.push(new FloatingText());
        }
      };

      p.draw = () => {
        // Tạo màu nền đen tối với ánh sáng mờ để tạo cảm giác ấm cúng
        p.background(15, 0, 30, 80); 
        for (let text of floatingTexts) {
          text.update();
          text.display();
        }
      };

      class FloatingText {
        constructor() {
          this.x = p.random(p.width);
          this.y = p.random(-p.height, p.height);
          this.speed = p.random(0.5, 2); // Tốc độ chậm hơn để tạo không khí nhẹ nhàng
          this.size = p.random(15, 40);
          // Màu sắc pha trộn giữa cam và vàng để tạo sự ấm áp, cộng thêm chút ánh sáng mờ ảo
          this.color = p.color(p.random(200, 255), p.random(100, 200), 100, 180);
          this.angle = p.random(0, p.TWO_PI); // Thêm chút chuyển động xoay nhẹ
        }

        update() {
          // Chuyển động dọc xuống, khởi động lại khi ra ngoài màn hình
          this.y += this.speed;
          this.angle += 0.01; // Cập nhật góc xoay
          if (this.y > p.height) {
            this.y = p.random(-200, -50);
            this.x = p.random(p.width);
            this.speed = p.random(0.5, 2);
          }
        }

        display() {
          p.push();
          p.translate(this.x, this.y);
          p.rotate(this.angle);
          p.fill(this.color);
          p.textSize(this.size);
          p.text("🎶 Music Vibes 🎶", 0, 0);
          p.pop();
        }
      }
    };

    new p5(sketch);

    // Cleanup function để loại bỏ canvas khi component bị gỡ bỏ
    return () => {
      const canvas = document.querySelector('canvas');
      if (canvas) {
        canvas.remove();
      }
    };
  }, []);

  return null; // Component không cần render gì cả
};

export default P5Effect;
