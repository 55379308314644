// P5Effect.js
import React, { useEffect } from 'react';
import p5 from 'p5';


const P5Effect = () => {
  useEffect(() => {
    const sketch = (p) => {
      let nftMusicWords = [];

      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight);
        p.textFont('Arial');
        p.textSize(20);
        for (let i = 0; i < 100; i++) {
          nftMusicWords.push(new NFTMusic());
        }
      };

      p.draw = () => {
        p.background(0, 0, 25);
        for (let word of nftMusicWords) {
          word.update();
          word.display();
        }
      };

      class NFTMusic {
        constructor() {
          this.x = p.random(p.width);
          this.y = p.random(-p.height, p.height);
          this.speed = p.random(1, 5);
          this.size = p.random(10, 30);
          this.color = p.color(p.random(100, 255), p.random(100, 255), 255, 150);
        }

        update() {
          this.y += this.speed;
          if (this.y > p.height) {
            this.y = p.random(-200, -50);
            this.x = p.random(p.width);
            this.speed = p.random(1, 5);
            this.size = p.random(10, 30);
          }
        }

        display() {
          p.fill(this.color);
          p.textSize(this.size);
          p.text("NFT MUSIC", this.x, this.y);
        }
      }
    };

    new p5(sketch);

    // Cleanup function to remove the canvas when the component is unmounted
    return () => {
      const canvas = document.querySelector('canvas');
      if (canvas) {
        canvas.remove();
      }
    };
  }, []);

  return null; // Component không cần render gì cả
};

export default P5Effect;
