import React, { useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import { getContract } from '../services/contract';
import { pinataApiKey, pinataSecretApiKey } from '../abis/config';
import '../css/MintNFT.css';
import P5Effect from '../p5/P5Effect'; 

const maxFileSize = 100 * 1024 * 1024; // 100MB

const Mint = () => {
    const [formData, setFormData] = useState({
        name: '',
        artist: '',
        description: '',
        amount: 1
    });
    const [imageFile, setImageFile] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [agreeTerms, setAgreeTerms] = useState(false); 
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'description' && value.length > 150) {
            alert('Description cannot exceed 150 characters.');
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        

    };

    const handleCheckboxChange = (e) => {
        setAgreeTerms(e.target.checked); 
    };

    const uploadToIPFS = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        const res = await axios.post(`https://api.pinata.cloud/pinning/pinFileToIPFS`, formData, {
            maxContentLength: Infinity,
            headers: {
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataSecretApiKey,
                'Content-Type': 'multipart/form-data',
            },
        });

        return res.data.IpfsHash;
    };

    const uploadMetadataToIPFS = async (name, artist, description, imageHash, audioHash) => {
        const metadata = {
            name,
            artist,
            description,
            image: `ipfs://${imageHash}`,
            audio: `ipfs://${audioHash}`,
        };

        const res = await axios.post(`https://api.pinata.cloud/pinning/pinJSONToIPFS`, metadata, {
            headers: {
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataSecretApiKey,
            },
        });

        return res.data.IpfsHash;
    };

    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp'];

        if (file && validImageTypes.includes(file.type) && file.size <= maxFileSize) {
            setImageFile(file);
        } else {
            alert('Please select a valid image file (PNG, JPEG, JPG, GIF, BMP) and no more than 100MB.');
            setImageFile(null);
        }
    };

    const handleAudioFileChange = (e) => {
        const file = e.target.files[0];
        const validAudioTypes = ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg', 'audio/flac'];

        if (file && validAudioTypes.includes(file.type) && file.size <= maxFileSize) {
            setAudioFile(file);
        } else {
            alert('Please select a valid audio file (MP3, WAV, OGG, FLAC) and no larger than 100MB.');
            setAudioFile(null);
        }
    };

    const handleMint = async (event) => {
        event.preventDefault();
    
        if (!agreeTerms) {
            alert('Please agree to the terms before continuing.');
            return;
        }
    
        setLoading(true);
    
        try {
            const { name, artist, description, amount } = formData;
    
            if (!name || !artist || !description || !imageFile || !audioFile) {
                alert('Please fill in the information completely and upload both images and audio.');
                setLoading(false);
                return;
            }
    
            // 1. Tạo kết nối contract và chuẩn bị giao dịch
            const contract = getContract();
            const price = ethers.utils.parseEther('0.1');
            const tx = await contract.mint(
                name,
                artist,
                description,
                '', // Temporary empty hash for image
                '', // Temporary empty hash for audio
                amount,
                { value: price }
            );
    
            // 2. Chờ xác nhận giao dịch trong ví
            const receipt = await tx.wait();
            console.log('Transaction confirmed:', receipt);
    
            // 3. Nếu giao dịch được xác nhận, upload các tệp lên IPFS
            const imageHash = await uploadToIPFS(imageFile);
            const audioHash = await uploadToIPFS(audioFile);
            const metadataHash = await uploadMetadataToIPFS(name, artist, description, imageHash, audioHash);
            const baseURI = `ipfs://${metadataHash}/`;
    
            // 4. Gọi hàm cập nhật metadata trên smart contract (nếu cần)
            console.log('IPFS Hashes:', { imageHash, audioHash, metadataHash });
    
            alert('NFT minted and metadata uploaded successfully!');
        } catch (error) {
            console.error('Minting failed:', error);
            alert('Mint failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="container">
            <P5Effect />
            <h1>Mint Your Music NFT</h1>
            <form onSubmit={handleMint}>
                <input
                    type="text"
                    placeholder="NFT Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    placeholder="Artist"
                    name="artist"
                    value={formData.artist}
                    onChange={handleChange}
                    required
                />
                <textarea
                    placeholder="Description (max 150 characters)"
                    name="description"
                    value={formData.description}
                    onChange={(e) => {
                        if (e.target.value.length <= 150) {
                            handleChange(e);
                        }
                    }}
                    maxLength={150}
                    required
                />
                
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageFileChange}
                    required
                />
                <input
                    type="file"
                    accept="audio/*"
                    onChange={handleAudioFileChange}
                    required
                />
                <div className="terms-container">
                    <input
                        type="checkbox"
                        id="terms"
                        checked={agreeTerms}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="terms">
                    I agree <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
                    </label>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Minting...' : 'Mint NFT'}
                </button>
            </form>
        </div>
    );
};

export default Mint;
