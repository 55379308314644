import React, { useEffect, useState } from 'react';
import { getContract } from '../services/contract';
import { ethers } from 'ethers';
import '../css/Profile.css';
import verifiedIcon from '../assets/verified.png';

const Profile = () => {
    const [ownedNFTs, setOwnedNFTs] = useState([]);
    const [metadata, setMetadata] = useState({});
    const [priceInputs, setPriceInputs] = useState({});
    const [itemsForSale, setItemsForSale] = useState({});
    const [connectedAddress, setConnectedAddress] = useState('');
    const [ratings, setRatings] = useState({});
    const [showVerificationForm, setShowVerificationForm] = useState(false);
    const [verificationData, setVerificationData] = useState({
        artistName: '',
        walletAddress: '',
        spotifyLink: '',
        appleMusicLink: '',
        youtubeLink: ''
    });

    useEffect(() => {
        async function fetchOwnedNFTs() {
            try {
                const contract = getContract();
                const address = await contract.signer.getAddress();
                setConnectedAddress(address);
                const tokens = await contract.getOwnedNFTs(address);

                const metadataPromises = tokens.map(async (tokenId) => {
                    const metadata = await contract.metadata(tokenId);
                    const artistAddress = await contract.nameToAddress(metadata.artist);

                    let isVerified = false;
                    try {
                        isVerified = await contract.isVerifiedArtistForAddress(metadata.artist, address);
                    } catch (error) {
                        console.warn(`Verification check failed for artist: ${metadata.artist}`, error);
                    }

                    const isForSale = (await contract.salePrices(tokenId)) > 0;
                    const rating = await contract.getRating(tokenId).catch(() => 0);

                    return { tokenId, metadata: { ...metadata, isVerified }, isForSale, rating };
                });

                const metadataResults = await Promise.all(metadataPromises);

                setOwnedNFTs(metadataResults.map(item => item.tokenId));
                setMetadata(metadataResults.reduce((acc, item) => ({ ...acc, [item.tokenId]: item.metadata }), {}));
                setItemsForSale(metadataResults.reduce((acc, item) => ({ ...acc, [item.tokenId]: item.isForSale }), {}));
                setRatings(metadataResults.reduce((acc, item) => ({ ...acc, [item.tokenId]: item.rating }), {}));
            } catch (error) {
                console.error("Error fetching owned NFTs:", error);
            }
        }

        fetchOwnedNFTs();
    }, []);

    const handleSell = async (tokenId) => {
        try {
            const price = priceInputs[tokenId];
            if (!price) return alert("Please enter a price.");
            const contract = getContract();
            await contract.listForSale(tokenId, ethers.utils.parseEther(price));
            alert("NFT listed for sale successfully.");
            setItemsForSale({ ...itemsForSale, [tokenId]: true });
        } catch (error) {
            console.error("Error listing NFT for sale:", error);
            alert("Failed to list NFT for sale.");
        }
    };

    const handleCancelListing = async (tokenId) => {
        try {
            const contract = getContract();
            await contract.cancelListing(tokenId);
            alert("Listing canceled.");
            setItemsForSale({ ...itemsForSale, [tokenId]: false });
        } catch (error) {
            console.error("Error canceling listing:", error);
            alert("Failed to cancel listing.");
        }
    };

    const handlePriceInputChange = (tokenId, value) => {
        setPriceInputs({ ...priceInputs, [tokenId]: value });
    };

    const handleVerificationRequest = () => {
        setShowVerificationForm(true);
    };

    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("https://xac-minh.onrender.com/api/verify-artist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(verificationData)
            });

            if (response.ok) {
                alert("Verification request submitted successfully!");
            } else {
                alert("Failed to submit verification request.");
            }
        } catch (error) {
            console.error("Error submitting verification request:", error);
            alert("An error occurred while submitting your request.");
        }
        setShowVerificationForm(false);
    };

    const handleVerificationInputChange = (e) => {
        const { name, value } = e.target;
        setVerificationData({ ...verificationData, [name]: value });
    };

    return (
        <div className="profile-container">
            <button onClick={handleVerificationRequest} className="verification-button">
                Request Verification
            </button>
            <h1>Your NFTs</h1>

            {ownedNFTs.length > 0 ? (
                <div className="nft-grid">
                    {ownedNFTs.map((tokenId) => (
                        <div className="nft-card" key={tokenId}>
                            <img
                                src={`https://ipfs.io/ipfs/${metadata[tokenId]?.ipfsHashImage}`}
                                alt="NFT"
                                className="nft-image"
                            />
                            <div className="nft-tooltip">
                                <p><strong>NFT ID:</strong> {tokenId.toString()}</p>
                                <p><strong>Name:</strong> {metadata[tokenId]?.name}</p>
                                <p>
                                    <strong>Artist:</strong> {metadata[tokenId]?.artist}
                                    {metadata[tokenId]?.isVerified && (
                                        <img src={verifiedIcon} alt="Verified" className="verified-icon" />
                                    )}
                                </p>
                                <p><strong>Description:</strong> {metadata[tokenId]?.description}</p>
                                <div className="rating">
                                    <span>Rating: </span>
                                    {[...Array(5)].map((_, index) => {
                                        const starClass = () => {
                                            if (index < ratings[tokenId]) {
                                                if (ratings[tokenId] <= 2) return 'filled';
                                                if (ratings[tokenId] === 3) return 'filled yellow';
                                                if (ratings[tokenId] === 4) return 'filled purple';
                                                if (ratings[tokenId] === 5) return 'filled red';
                                            }
                                            return '';
                                        };

                                        return (
                                            <span key={index} className={`star ${starClass()}`}>
                                                ★
                                            </span>
                                        );
                                    })}
                                </div>
                                {itemsForSale[tokenId] ? (
                                    <button onClick={() => handleCancelListing(tokenId)}>Cancel Listing</button>
                                ) : (
                                    <div className="sell-section">
                                        <input
                                            type="text"
                                            placeholder="Enter price in POL"
                                            value={priceInputs[tokenId] || ""}
                                            onChange={(e) => handlePriceInputChange(tokenId, e.target.value)}
                                        />
                                        <button onClick={() => handleSell(tokenId)}>Sell</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No NFTs owned.</p>
            )}

            {showVerificationForm && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Verification Request Form</h2>
                        <form onSubmit={handleVerificationSubmit}>
                            <label>Artist Name:</label>
                            <input
                                type="text"
                                name="artistName"
                                value={verificationData.artistName}
                                onChange={handleVerificationInputChange}
                                required
                            />
                            <label>Wallet Address:</label>
                            <input
                                type="text"
                                name="walletAddress"
                                value={verificationData.walletAddress}
                                onChange={handleVerificationInputChange}
                                required
                            />
                            <label>Spotify Link (Optional):</label>
                            <input
                                type="text"
                                name="spotifyLink"
                                value={verificationData.spotifyLink}
                                onChange={handleVerificationInputChange}
                            />
                            <label>Apple Music Link (Optional):</label>
                            <input
                                type="text"
                                name="appleMusicLink"
                                value={verificationData.appleMusicLink}
                                onChange={handleVerificationInputChange}
                            />
                            <label>Contact Email:</label>
                            <input
                                type="text"
                                name="youtubeLink"
                                value={verificationData.youtubeLink}
                                onChange={handleVerificationInputChange}
                            />
                            <div className="button-group">
                                <button type="submit">Submit Verification Request</button>
                                <button type="button" onClick={() => setShowVerificationForm(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile;
