import React, { useEffect, useState } from 'react';
import { getContract } from '../services/contract';
import { ethers } from 'ethers';
import '../css/Marketplace.css';
import verifiedIcon from '../assets/verified.png';

const Marketplace = () => {
    const [nfts, setNfts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedNFT, setSelectedNFT] = useState(null);
    const [rating, setRating] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredNFTs, setFilteredNFTs] = useState([]);
    const itemsPerPage = 20;

    useEffect(() => {
        async function fetchNFTs() {
            try {
                const contract = await getContract();
                const [tokenIds, prices, sellers] = await contract.getItemsForSale();

                const nftData = await Promise.all(
                    tokenIds.map(async (tokenId, index) => {
                        const metadata = await contract.metadata(tokenId);
                        const price = prices[index];
                        const seller = sellers[index];
                        const averageRating = await contract.getRating(tokenId).catch(() => 0);

                        let isVerified = false;
                        try {
                            isVerified = await contract.isVerifiedArtist(seller);
                        } catch (error) {
                            console.warn(`Verification check failed for seller: ${seller}`, error);
                        }

                        if (price.eq(0)) return null;

                        return {
                            tokenId: tokenId.toNumber(),
                            price,
                            seller,
                            imageHash: metadata.ipfsHashImage,
                            description: metadata.description,
                            averageRating,
                            name: metadata.name,
                            artist: metadata.artist,
                            isVerified,
                        };
                    })
                );

                const uniqueNFTs = nftData
                    .filter((nft) => nft !== null)
                    .reduce((acc, nft) => {
                        if (!acc.find((item) => item.tokenId === nft.tokenId)) {
                            acc.push(nft);
                        }
                        return acc;
                    }, []);

                setNfts(uniqueNFTs);
                setFilteredNFTs(uniqueNFTs);
            } catch (error) {
                console.error('Error fetching NFTs:', error);
            }
        }
        fetchNFTs();
    }, []);

    const handleSearch = () => {
        const query = searchQuery.toLowerCase();
        const results = nfts.filter(
            (nft) =>
                nft.seller.toLowerCase().includes(query) ||
                nft.artist.toLowerCase().includes(query)
        );
        setFilteredNFTs(results);
        setCurrentPage(1);
    };

    const handleBuy = async (tokenId, price) => {
        try {
            const contract = await getContract();
            await contract.buy(tokenId, { value: price });
            alert('NFT purchased successfully!');
            setFilteredNFTs(filteredNFTs.filter((nft) => nft.tokenId !== tokenId));
            setSelectedNFT(null);
        } catch (error) {
            console.error('Error purchasing NFT:', error);
        }
    };

    const handleRate = async (tokenId) => {
        try {
            const contract = await getContract();
            await contract.rateNFT(tokenId, rating);
            alert('Successfully rated!');
            setSelectedNFT((prev) => ({ ...prev, averageRating: (prev.averageRating + rating) / 2 }));
        } catch (error) {
            console.error('Error rating NFT:', error);
        }
    };

    const handleShowDetails = (nft) => {
        setSelectedNFT(nft);
        setRating(0);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentNFTs = filteredNFTs.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredNFTs.length / itemsPerPage);

    return (
        <div className="marketplace-container">
            <h1>Marketplace</h1>

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by artist name or wallet address"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            <div className="nft-grid">
                {currentNFTs.map((nft) => (
                    <div className="nft-card" key={nft.tokenId} onClick={() => handleShowDetails(nft)}>
                        <img
                            src={`https://ipfs.io/ipfs/${nft.imageHash}`}
                            alt={`NFT ${nft.tokenId}`}
                            className="nft-image"
                        />
                        <h2>{nft.name}</h2>
                        <p>
                            Artist: {nft.artist}
                            {nft.isVerified && <img src={verifiedIcon} alt="Verified" className="verified-icon" />}
                        </p>
                        <p>Price: {ethers.utils.formatEther(nft.price)} POL</p>
                        <div className="rating">
                            <span>Rating: </span>
                            {[...Array(5)].map((_, index) => {
                                const starClass = () => {
                                    if (index < nft.averageRating) {
                                        if (nft.averageRating <= 2) return 'filled';
                                        if (nft.averageRating === 3) return 'filled yellow';
                                        if (nft.averageRating === 4) return 'filled purple';
                                        if (nft.averageRating === 5) return 'filled red';
                                    }
                                    return '';
                                };

                                return (
                                    <span key={index} className={`star ${starClass()}`}>
                                        ★
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>

            {selectedNFT && (
                <div className="nft-detail-overlay" onClick={() => setSelectedNFT(null)}>
                    <div className="nft-detail" onClick={(e) => e.stopPropagation()}>
                        <img
                            src={`https://ipfs.io/ipfs/${selectedNFT.imageHash}`}
                            alt={`NFT ${selectedNFT.tokenId}`}
                            className="nft-detail-image"
                        />
                        <h2>{selectedNFT.name}</h2>
                        <p>
                            <strong>Artist:</strong> {selectedNFT.artist}
                            {selectedNFT.isVerified && <img src={verifiedIcon} alt="Verified" className="verified-icon" />}
                        </p>
                        <p><strong>Description:</strong> {selectedNFT.description}</p>
                        <p><strong>Seller:</strong> {selectedNFT.seller}</p>
                        <p><strong>Price:</strong> {ethers.utils.formatEther(selectedNFT.price)} POL</p>
                        <div className="rating">
                            <span>Rating: </span>
                            {[...Array(5)].map((_, index) => {
                                const starClass = () => {
                                    if (index < selectedNFT.averageRating) {
                                        if (selectedNFT.averageRating <= 2) return 'filled';
                                        if (selectedNFT.averageRating === 3) return 'filled yellow';
                                        if (selectedNFT.averageRating === 4) return 'filled purple';
                                        if (selectedNFT.averageRating === 5) return 'filled red';
                                    }
                                    return '';
                                };

                                return (
                                    <span key={index} className={`star ${starClass()}`}>
                                        ★
                                    </span>
                                );
                            })}
                        </div>
                        <button onClick={() => handleBuy(selectedNFT.tokenId, selectedNFT.price)}>Buy</button>
                    </div>
                </div>
            )}

            <div className="pagination">
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        className={`page-button ${index + 1 === currentPage ? 'active' : ''}`}
                        onClick={() => paginate(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
            
        </div>
    );
};

export default Marketplace;
