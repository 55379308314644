// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import MainApp from './MainApp';
import './index.css'; 

ReactDOM.render(
    <React.StrictMode>
        <MainApp />
    </React.StrictMode>,
    document.getElementById('root')
);
