import React from 'react';
import '../css/Home.css';
import sponsorLogo from '../assets/nftmusic.png';
import facebookIcon from '../assets/facebook.webp';
import xIcon from '../assets/x.png';
import youtubeIcon from '../assets/youtube.png';

const Home = () => {
  return (
    <div className="home-container">
      <header className="home-header">
      <div className="sponsor-logo">
        <img src={sponsorLogo} alt="Sponsor Logo" />
      </div>
        <h1>Welcome to MusicNFT</h1>
        <p>Your platform to buy, sell, and own music as NFTs. Discover a new way to experience music!</p>
      </header>
      
      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>We aim to empower artists and music lovers by offering a blockchain-based platform where music can be tokenized as NFTs. Here, you can create, trade, and own unique digital music assets that represent true ownership.</p>
      </section>
      
      <section className="video-section">
        <h2>How to Use MusicNFT</h2>
        <p>Watch the video below to get started with MusicNFT. Learn how to connect your wallet, mint NFTs, and explore the marketplace.</p>
        <div className="video-container">
          <iframe
            width="800"
            height="450"
            src="https://www.youtube.com/embed/F64jMp3gqZc"  
            title="MusicNFT Guide"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>
      
      <section className="benefits-section">
        <h2>Why Music NFTs?</h2>
        <ul>
          <li><strong>True Ownership:</strong> Own your music as a unique digital asset with verified ownership on the blockchain.</li>
          <li><strong>Support Artists:</strong> Buy and sell music directly from the creators, cutting out intermediaries.</li>
          <li><strong>Scarcity and Value:</strong> Just like physical collectibles, each music NFT is unique, giving it value and rarity.</li>
          <li><strong>Engage with the Community:</strong> Join a vibrant community of music enthusiasts and creators, sharing and discovering new music.</li>
        </ul>
      </section>

      <section className="cta-section">
        <h2>Start Your Journey</h2>
        <p>Connect your wallet and begin exploring the world of music NFTs. Mint, buy, or sell your favorite music tracks!</p>
      </section>

      <footer className="footer">
        <div className="sponsor-logo">
          <img src={sponsorLogo} alt="Sponsor Logo" />
        </div>
        
        <div className="contact-info">
          <p>Contact us: team.nftmusicplayer@gmail.com </p>
        </div>

        <div className="social-media">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://x.com/nftmusic2024" target="_blank" rel="noopener noreferrer">
            <img src={xIcon} alt="X (Twitter)" />
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <img src={youtubeIcon} alt="YouTube" />
          </a>
        </div>

        <p className="copyright">
          &copy; 2024 MusicNFT. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default Home;
