import React, { useEffect, useState, useRef } from 'react';
import { getContract } from '../services/contract';
import '../css/Music.css';
import LightEffect from '../p5/LightEffect'; 

const Music = () => {
    const [ownedNFTs, setOwnedNFTs] = useState([]);
    const [currentTrack, setCurrentTrack] = useState(0);
    const [audioSource, setAudioSource] = useState(null);
    const [currentTrackMetadata, setCurrentTrackMetadata] = useState({ name: '', artist: '', image: '' });
    const audioRef = useRef(null);

    useEffect(() => {
        async function fetchOwnedNFTs() {
            try {
                const contract = getContract();
                const address = await contract.signer.getAddress();
                const tokens = await contract.getOwnedNFTs(address);
                const formattedTokens = tokens.map(token => token.toString());
                setOwnedNFTs(formattedTokens);
            } catch (error) {
                console.error("Error fetching owned NFTs:", error);
            }
        }
        fetchOwnedNFTs();
    }, []);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('ended', handleNextTrack);
        }
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('ended', handleNextTrack);
            }
        };
    }, [audioSource]);

    const handlePlayMusic = async (tokenId) => {
        try {
            const contract = getContract();
            const accessToken = await contract.getAudioAccessToken(tokenId);
            const audioUrl = await fetchAudioUri(tokenId);
            setAudioSource(audioUrl);
            fetchMetadata(tokenId);
    
            if (audioRef.current) {
                // Thêm sự kiện để đảm bảo chỉ phát nhạc khi nguồn đã sẵn sàng
                audioRef.current.oncanplay = () => {
                    audioRef.current.play().catch(error => {
                        console.error("Error playing audio:", error);
                        alert("Failed to play audio.");
                    });
                };
                audioRef.current.load();
            }
        } catch (error) {
            console.error("Error fetching audio:", error);
            alert("Failed to retrieve audio.");
        }
    };
    

    const fetchAudioUri = async (accessToken) => {
        try {
            const userAddress = await getContract().signer.getAddress();
            const response = await fetch('https://audio-80dk.onrender.com/get-audio-uri/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tokenId: accessToken,
                    userAddress: userAddress
                }),
            });
            const data = await response.json();
            if (data.audioUri) {
                return data.audioUri;
            } else {
                throw new Error('Audio URI not found');
            }
        } catch (error) {
            console.error("Error fetching audio URI from API:", error);
            alert("Unable to fetch the audio.");
            throw error;
        }
    };

    const fetchMetadata = async (tokenId) => {
        try {
            const contract = getContract();
            const metadata = await contract.metadata(tokenId);
            const imageUrl = metadata.ipfsHashImage ? `https://ipfs.io/ipfs/${metadata.ipfsHashImage}` : '';
            setCurrentTrackMetadata({
                name: metadata.name,
                artist: metadata.artist,
                image: imageUrl,
            });
        } catch (error) {
            console.error("Error fetching metadata:", error);
        }
    };

    const handleNextTrack = () => {
        if (currentTrack + 1 < ownedNFTs.length) {
            setCurrentTrack((prevTrack) => prevTrack + 1);
            handlePlayMusic(ownedNFTs[currentTrack + 1]);
        }
    };

    const handlePreviousTrack = () => {
        if (currentTrack > 0) {
            setCurrentTrack((prevTrack) => prevTrack - 1);
            handlePlayMusic(ownedNFTs[currentTrack - 1]);
        }
    };

    return (
        <div className="music-room">
            <LightEffect /> {/* Hiển thị hiệu ứng p5.js chỉ trên trang Music */}
            
            <h1>MY MUSIC ROOM</h1>
            <div className="music-player">
                {ownedNFTs.length > 0 ? (
                    <>
                        <div className="track-info">
                            {currentTrackMetadata.image && (
                                <img src={currentTrackMetadata.image} alt="NFT cover" width="200" height="100" style={{ borderRadius: '30%' }} />
                            )}
                            <p>Song: {currentTrackMetadata.name}</p>
                            <p>Artist: {currentTrackMetadata.artist}</p>
                            <p>NFT ID: {ownedNFTs[currentTrack]}</p>
                        </div>
                        <div className="controls">
                            <button onClick={handlePreviousTrack} disabled={currentTrack === 0}>Previous</button>
                            <button onClick={() => handlePlayMusic(ownedNFTs[currentTrack])}>Play</button>
                            <button onClick={handleNextTrack} disabled={currentTrack === ownedNFTs.length - 1}>Next</button>
                        </div>
                        <div className="audio-player">
                            <audio ref={audioRef} controls autoPlay>
                                <source src={audioSource} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </>
                ) : (
                    <p>No music NFTs owned.</p>
                )}
            </div>
        </div>
    );
};

export default Music;
