// src/services/contract.js
import { ethers } from 'ethers';
import { MusicNFTABI } from '../abis/MusicNFTABI';
import { CONTRACT_ADDRESS } from '../abis/config';

export const getContract = () => {
    if (!window.ethereum) {
        throw new Error('Ethereum provider not found');
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(CONTRACT_ADDRESS, MusicNFTABI, signer);
};
