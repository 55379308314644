import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './Home';
import Marketplace from './Marketplace';
import Profile from './Profile';
import Music from './Music';
import Mint from './Mint';
import Terms from './Terms';
import NotificationDialog from './NotificationDialog';
import '../css/App.css';
import usePageTracking from './usePageTracking';
import mixpanel from 'mixpanel-browser';

const App = () => {
    usePageTracking(); 
    const [walletAddress, setWalletAddress] = useState(null);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [videoId, setVideoId] = useState("");
    const location = useLocation();
    mixpanel.init("e50172d1819460aeed933a706e3111e7"); 

    const POLYGON_CHAIN_ID = "0x89"; // Polygon Mainnet Chain ID

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                // Switch to Polygon network
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: POLYGON_CHAIN_ID }],
                });

                // Request wallet connection
                const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setWalletAddress(address);
            } catch (error) {
                if (error.code === 4902) {
                    alert("Polygon network is not added to your wallet. Please add it and try again.");
                } else {
                    console.error("Failed to connect wallet", error);
                }
            }
        } else {
            alert("Please install MetaMask!");
        }
    };

    useEffect(() => {
        // Automatically connect to the wallet and set the network if the wallet is already connected
        const checkWalletConnection = async () => {
            if (window.ethereum) {
                try {
                    const [address] = await window.ethereum.request({ method: 'eth_accounts' });
                    if (address) {
                        await window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: POLYGON_CHAIN_ID }],
                        });
                        setWalletAddress(address);
                    }
                } catch (error) {
                    console.error("Error while checking wallet connection", error);
                }
            }
        };
        checkWalletConnection();
    }, []);

    // WebSocket connection and receiving notifications
    useEffect(() => {
        const socket = new WebSocket("ws://localhost:5000");

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setNotificationMessage(data.message);
            setVideoId(data.videoId);
            setNotificationOpen(true); // Show notification dialog
        };

        return () => socket.close();
    }, []);

    // Function to get active class for the current page
    const getActiveClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <div>
            {/* Navigation Bar */}
            <nav>
                <Link to="/" className={getActiveClass('/')}>Home</Link>
                <Link to="/marketplace" className={getActiveClass('/marketplace')}>Marketplace</Link>
                <Link to="/profile" className={getActiveClass('/profile')}>Profile</Link>
                <Link to="/music" className={getActiveClass('/music')}>Play Music</Link>
                <Link to="/mint" className={getActiveClass('/mint')}>Mint NFT</Link>
                <button onClick={connectWallet}>
                    {walletAddress ? `Connected: ${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}` : "Connect Wallet"}
                </button>
            </nav>

            {/* Routes for each page */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/music" element={<Music />} />
                <Route path="/mint" element={<Mint />} />
                <Route path="/terms" element={<Terms />} />
            </Routes>

            {/* Notification Dialog - Only shows on Home page */}
            {location.pathname === "/" && (
                <NotificationDialog
                    open={notificationOpen}
                    onClose={() => setNotificationOpen(false)}
                    message={notificationMessage}
                    videoId={videoId}
                />
            )}
        </div>
    );
};

export default App;
