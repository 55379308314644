import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import YouTube from 'react-youtube';

const NotificationDialog = ({ open, onClose, message, videoId }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Thông báo sự kiện</DialogTitle>
            <DialogContent dividers>
                <p>{message}</p>
                {videoId && (
                    <YouTube
                        videoId={videoId}
                        opts={{ width: '100%', height: '300' }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotificationDialog;
