import React from 'react';
import '../css/Terms.css';

const Terms = () => {
    return (
        <div className="terms-container">
            <h1>Terms of Service</h1>
            <p>Welcome to our Music NFT platform! By using this platform, you agree to the following terms:</p>
            <section>
                <h2>1. Minting Fees</h2>
                <p>To mint an NFT, users must pay a minting fee of <strong>0.1 POL</strong>. This fee is non-refundable and will be used to support platform maintenance.</p>
            </section>
            <section>
                <h2>2. Artist Verification</h2>
                <p>Artists must register and verify their accounts to access full features, such as listing NFTs for sale. Verification status is determined by the platform administrator.</p>
            </section>
            <section>
                <h2>3. Ownership Rights</h2>
                <p>Ownership of an NFT provides access to its associated media files (image/audio) but does not transfer copyright or intellectual property rights.</p>
            </section>
            <section>
                <h2>4. Marketplace Fees</h2>
                <p>
                    A marketplace fee of <strong>2%</strong> is applied to all sales transactions. Additionally, a <strong>royalty fee</strong> is distributed to the original creator for each resale.
                </p>
            </section>
            <section>
                <h2>5. Prohibited Actions</h2>
                <ul>
                    <li>Attempting to mint content that infringes on others' intellectual property rights.</li>
                    <li>Engaging in fraudulent or abusive transactions.</li>
                </ul>
            </section>
            <section>
                <h2>6. Disclaimer</h2>
                <p>
                    The platform is not liable for any loss, damages, or disputes arising from NFT transactions. Users are responsible for ensuring compliance with applicable laws.
                </p>
            </section>
            <p>By minting or trading NFTs, you agree to comply with these terms. If you do not agree, please do not use the platform.</p>
            <p>
                For more details, contact our support team at <a href="mailto:support@musicnft.com">support@musicnft.com</a>.
            </p>
        </div>
    );
};

export default Terms;
